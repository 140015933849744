import { tryFetchURL } from './Helpers';

/**
 * Modernizr is defined in the index.html file from the custom export.
 * It is a map of tests to booleans if they passed or not.
 */
declare const Modernizr: Record<string, boolean>;

export interface IWarning {
  warning: string;
}

/**
 * TestResult can be either an error, a warning or a passed state.
 */
export type TestResult = Error | IWarning | 'passed';

/**
 * TestFunction is a function which provides a test result.
 */
type TestFunction = () => Promise<TestResult>;

export interface URLTest {
  name: string;
  url: string;
  niceURL: string;
  expectedCodes?: number[];
  expectedText?: string;
  expectedTextContains?: string;
  info?: string;
  useExternalTest?: boolean;
}

export type ErrorGroup = 'url' | 'browser';

export interface ISubTest {
  name: string;
  run: TestFunction;
  errorMsg: string;
  errorGroup: ErrorGroup;
  info?: string;
  useExternalTest: boolean;
}

export interface ITest {
  name: string;
  subTests: ISubTest[];
  description?: string[];
  ifProduct?: string;
}

interface IBrowserFeatureTest {
  name: string;
  passed: boolean;
}

const getURLCheckTest = (urlTest: URLTest): TestFunction => {
  return async (): Promise<TestResult> => {
    const forceFail = window.location.search.includes('fail=urls');
    const ok = await tryFetchURL(urlTest);
    return ok && !forceFail ? 'passed' : new Error(`failed to load ${urlTest.name}`);
  };
};

const getFeatureCheckTest = (featureTest: IBrowserFeatureTest): TestFunction => {
  return async (): Promise<TestResult> => {
    const forceFail = window.location.search.includes('fail=browser');
    return featureTest.passed && !forceFail
      ? 'passed'
      : new Error(`browser does not support ${featureTest.name}`);
  };
};

const sparxHostname = 'sparxmaths.uk';
const sparxProtocol = 'https://';
const sparxMathsCurriculumHostname = 'sparxmathscurriculum.com';

const studentAPIURL = `${sparxProtocol}studentapi.api.${sparxHostname}`;
const teacherAPIURL = `${sparxProtocol}teacherapi.api.${sparxHostname}`;
const swworkerAPIURL = `${sparxProtocol}swworker.api.${sparxHostname}`;
const authURL = `${sparxProtocol}auth.${sparxHostname}`;
const applicationURL = `${sparxProtocol}www.${sparxHostname}`;
const sparxMathsCurriculumApplicationURL = `${sparxProtocol}${sparxMathsCurriculumHostname}`;

const commonUrls: URLTest[] = [
  {
    name: 'Login URL',
    niceURL: authURL,
    url: authURL + '/ping',
    expectedText: 'pong',
  },
  {
    name: 'Asset URL',
    niceURL: 'https://assets.sparxhomework.uk',
    url: 'https://assets.sparxhomework.uk/ping',
    expectedText: 'pong',
  },
  {
    name: 'Static Asset URL',
    niceURL: 'https://static.sparxhomework.uk',
    url:
      // im-91: temporarily make this check the latest sparx web bundle
      'https://static.sparxhomework.uk/sw/e30ae6ab4523d3d0cc5a134ec4c1ebd877206415/sparxweb.js',
    // 'https://static.sparxhomework.uk/tp/5c7ebb2a3875d5e8365d07b2281bd3c4c6aef82f/static/img/favicons/favicon32x32.png'
  },
  {
    name: 'Static Games URL',
    niceURL: 'https://static.sparxhomework.uk (games)',
    url:
      'https://static.sparxhomework.uk/sw/e30ae6ab4523d3d0cc5a134ec4c1ebd877206415/games.sparxweb.js',
    info:
      'This check tests the same domain as the one above but tries to load a file with the word "games" in the path. If the above check passes and this check fails then your firewall may be blocking Sparx games content.',
  },
  {
    name: 'Static Game Assets URL',
    niceURL: 'https://static.sparxhomework.uk (game assets)',
    url:
      'https://static.sparxhomework.uk/assets/algenie-web/games/audio/quizgames/correct answer 1.mp3',
    info:
      'This check tests the same domain as the one above but tries to load a sound effect used in the Sparx games. If the above checks pass and this check fails then your firewall may be blocking Sparx games content.',
    expectedCodes: [200],
  },
  {
    name: 'Teacher API',
    niceURL: teacherAPIURL + ' (teachers only)',
    url: teacherAPIURL + '/ping',
    expectedText: 'pong',
    info: 'This is only required for teachers.',
  },

  // Google font URLs
  {
    name: 'Google Fonts',
    niceURL: 'https://fonts.gstatic.com',
    url:
      'https://fonts.gstatic.com/s/archivonarrow/v9/tss3ApVBdCYD5Q7hcxTE1ArZ0b5LrGld-9ICPRBv.woff2',
  },
  {
    name: 'Google Fonts 2',
    niceURL: 'https://fonts.googleapis.com',
    url: 'https://fonts.googleapis.com/css?family=Archivo+Narrow',
  },

  // Document360
  {
    name: 'Document360 CDN',
    niceURL: 'https://cdn.document360.io (teachers only)',
    url: 'https://cdn.document360.io/static/js/widget.js',
    info:
      'Our knowledge assistant and knowledge base require access to this domain to function. This is only required for teachers.',
  },

  // Canny
  {
    name: 'Canny Feedback',
    niceURL: 'https://canny.io (teachers only)',
    url: '', // Uses Canny Widget callback to check access.
    useExternalTest: true,
    info:
      'Our feedback boards require access to this domain to function. This is only required for teachers.',
  },

  // Cloudflare Turnstile
  {
    name: 'Cloudflare',
    niceURL: 'https://challenges.cloudflare.com',
    url: 'https://challenges.cloudflare.com',
    expectedTextContains: 'Turnstile is a Cloudflare security product',
    info: 'We use Cloudflare to protect our services from malicious traffic',
  },

  {
    name: 'Sparx Learning',
    niceURL: 'https://sparx-learning.com',
    url: 'https://sparx-learning.com/ping',
    expectedCodes: [200, 404],
    info:
      'Our main Sparx Learning web site, which can be an entry point for students and teachers to gain access to our systems.',
  },

  // Intercom
  {
    name: 'Intercom (1)',
    niceURL: 'https://*.intercom.io (teachers only)',
    url: 'https://widget.intercom.io/widget',
    info:
      'Our knowledge assistant and knowledge base require access to this domain to function. This is only required for teachers.',
    expectedTextContains: 'https://api-iam.intercom.io',
    expectedCodes: [200],
  },
  {
    name: 'Intercom (2)',
    niceURL: 'https://*.intercomcdn.com (teachers only)',
    url: 'https://js.intercomcdn.com/intersection/assets/styles.js',
    info:
      'Our knowledge assistant and knowledge base require access to this domain to function. This is only required for teachers.',
    expectedTextContains: 'style',
    expectedCodes: [200],
  },
  {
    name: 'Intercom (3)',
    niceURL: 'https://*.intercomassets.com (teachers only)',
    url: 'https://static.intercomassets.com/avatars/7880909/square_128/Screenshot_2024-11-14_at_13.32.43-1731591176.png', // Temporary usage of Lauren's avatar - this could change so we need a permanent asset to use
    info:
      'Our knowledge assistant and knowledge base require access to this domain to function. This is only required for teachers.',
    expectedCodes: [200],
  },
];

const sparxMathsUrls: URLTest[] = [
  {
    name: 'Website URL',
    niceURL: `https://sparxmaths.com`,
    url: `https://sparxmaths.com/ping2`,
    expectedCodes: [200, 404],
  },
  {
    name: 'Application URL',
    niceURL: applicationURL,
    url: applicationURL + '/ping?noredirect=true',
    expectedCodes: [404],
    expectedTextContains: 'Sparx',
  },
  {
    name: 'Student API (1)',
    niceURL: studentAPIURL,
    url: studentAPIURL + '/ping',
    expectedText: 'pong',
  },
  {
    name: 'Student API (2)',
    niceURL: swworkerAPIURL,
    url: swworkerAPIURL + '/sparx.messaging.worker.v1.Worker/SendLegacyClientRequest',
    // We expect this to fail
    // expectedText: 'gRPC requires HTTP/2\n',
    expectedCodes: [400, 405, 500],
  },
  {
    name: 'Sparx Maths Curriculum URL',
    niceURL: sparxMathsCurriculumApplicationURL,
    url: sparxMathsCurriculumApplicationURL + '/ping',
    info:
      'Our curriculum resources for Sparx Maths are available from this domain. This is only required for teachers.',
    expectedCodes: [200, 404],
  },
];

const readerHostname = 'sparxreader.com';

const sparxReaderUrls: URLTest[] = [
  {
    name: 'Website URL',
    niceURL: `${sparxProtocol}${readerHostname}`,
    url: `${sparxProtocol}${readerHostname}/ping2`,
    expectedCodes: [200, 404],
  },
  {
    name: 'Application URL',
    niceURL: `${sparxProtocol}app.${readerHostname}`,
    url: `${sparxProtocol}app.${readerHostname}/ping2`,
    expectedCodes: [200],
  },
  {
    name: 'API',
    niceURL: `${sparxProtocol}api.${readerHostname}`,
    url: `${sparxProtocol}api.${readerHostname}/ping`,
    expectedText: 'pong',
  },
];

const scienceHostname = 'sparxscience.com';

const sparxScienceUrls: URLTest[] = [
  {
    name: 'Website URL',
    niceURL: `${sparxProtocol}${scienceHostname}`,
    url: `${sparxProtocol}${scienceHostname}/ping2`,
    expectedCodes: [200, 404],
  },
  {
    name: 'Application URL',
    niceURL: `${sparxProtocol}app.${scienceHostname}`,
    url: `${sparxProtocol}app.${scienceHostname}/ping2`,
    expectedCodes: [200],
  },
  {
    name: 'API',
    niceURL: `${sparxProtocol}api.${scienceHostname}`,
    url: `${sparxProtocol}api.${scienceHostname}/ping`,
    expectedText: 'pong',
  },
];

const sparxAssessmentsUrls: URLTest[] = [
  {
    name: 'Application URL',
    niceURL: `${sparxProtocol}app.sparxassessments.com`,
    url: `${sparxProtocol}app.sparxassessments.com/ping`,
    expectedCodes: [200],
  },
  {
    name: 'API',
    niceURL: `${sparxProtocol}api.sparxassessments.com`,
    url: `${sparxProtocol}api.sparxassessments.com/ping`,
    expectedText: 'pong',
  },
];

// Map all of the modernizr features to tests.
const browserFeaturesToTest: IBrowserFeatureTest[] = Object.entries(Modernizr).map(([f, p]) => ({
  name: f,
  passed: p,
}));

const urlToTest = (url: URLTest): ISubTest => ({
  name: `${url.niceURL}`,
  run: getURLCheckTest(url),
  errorMsg: url.niceURL,
  errorGroup: 'url',
  info: url.info,
  useExternalTest: !!url.useExternalTest,
});

export const tests: ITest[] = [
  // URLs
  {
    name: 'Common URLs',
    subTests: commonUrls.map(urlToTest),
    description: [
      'The URLs below are all necessary for Sparx to function properly; please ensure they are whitelisted in your firewall for all devices and users.',
      'We highly recommend whitelisting the domains sparxmaths.uk and sparxhomework.uk rather than whitelisting individual subdomains.',
      'You can refresh this page to test any changes that you make.',
    ],
  },

  // Maths URLS
  {
    name: 'Sparx Maths URLs',
    subTests: sparxMathsUrls.map(urlToTest),
    description: [
      'The URLs below are all necessary for Sparx Maths to function properly; please ensure they are whitelisted in your firewall for all devices and users.',
      'We highly recommend whitelisting the domains sparxmaths.uk and sparxhomework.uk rather than whitelisting individual subdomains.',
      'You can refresh this page to test any changes that you make.',
    ],
    ifProduct: 'maths',
  },

  // Reader URLS
  {
    name: 'Sparx Reader URLs',
    subTests: sparxReaderUrls.map(urlToTest),
    description: [
      'The URLs below are all necessary for Sparx Reader to function properly; please ensure they are whitelisted in your firewall for all devices and users.',
      'We highly recommend whitelisting the domain sparxreader.com rather than whitelisting individual subdomains.',
      'You can refresh this page to test any changes that you make.',
    ],
    ifProduct: 'reader',
  },

  // Science URLS
  {
    name: 'Sparx Science URLs',
    subTests: sparxScienceUrls.map(urlToTest),
    description: [
      'The URLs below are all necessary for Sparx Science to function properly; please ensure they are whitelisted in your firewall for all devices and users.',
      'We highly recommend whitelisting the domain sparxscience.com rather than whitelisting individual subdomains.',
      'You can refresh this page to test any changes that you make.',
    ],
    ifProduct: 'science',
  },

  // Assessments URLS
  {
    name: 'Sparx Assessments URLs',
    subTests: sparxAssessmentsUrls.map(urlToTest),
    description: [
      'The URLs below are all necessary for Sparx Assessments to function properly; please ensure they are whitelisted in your firewall for all devices and users.',
      'We highly recommend whitelisting the domain sparxassessments.com rather than whitelisting individual subdomains.',
      'You can refresh this page to test any changes that you make.',
    ],
    ifProduct: 'assessments',
  },

  // Browser check
  {
    name: 'Browser Compatibility',
    subTests: browserFeaturesToTest.map(featureTest => ({
      name: featureTest.name,
      run: getFeatureCheckTest(featureTest),
      errorMsg: featureTest.name,
      errorGroup: 'browser',
      useExternalTest: false,
    })),
    description: [
      'Sparx requires functionality that is a part of most modern internet browsers. However, ' +
        'some older browsers might not have this functionality. Sparx may still be usable with ' +
        'these browsers, but we’d recommend updating to the most recent version of your browser, or ' +
        'using a more modern browser.',
      'You can visit this page in another browser to check if that has the functionality required.',
    ],
  },
];
